import { Component } from '@angular/core';
import { LoginService } from './../services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  activeLinkIndex: number = 0;

  navItems = [
    { label: 'Hem', link: ['/home'] },
    { label: 'Spelrätter', link: ['/playrights'] },
  //  { label: 'Tävlingar', link: ['/events'] },
    { label: 'Medlemsinfo', link: ['/memberinfo'] },
    { label: 'Anslagstavla', link: ['/chat'] },
    { label: 'Din Profil', link: ['/setting'] }
  ];

  adminNavItems = [
    { label: 'Admin-Users', link: ['/admin_users'] }
  ]

  constructor(
    private loginService: LoginService,
    private router: Router) {
  }

  log_button_clicked() {
    if (this.loginService.authenticated) {
      this.router.navigate(['logout']);
    }
    else {
      this.router.navigate(['login'])
    }
  }
}
