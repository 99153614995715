
import { AngularFireAuth } from '@angular/fire/auth';


export const firebaseConfig = {
    apiKey: "AIzaSyC28OhubTaAbDy63c66aneJKD6hKT_vkDs",
    authDomain: "viff-golf-96ad6.firebaseapp.com",
    databaseURL: "https://viff-golf-96ad6.firebaseio.com",
    storageBucket: "viff-golf-96ad6.appspot.com",
    messagingSenderId: "661407483828"
  };

