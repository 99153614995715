export { AppComponent } from './app.component';
export { LoginComponent } from './login/login.component';
export { LogoutComponent } from './logout/logout.component';
export { PlayrightsComponent } from './playrights/playrights.component'
export { HomeComponent } from './home/home.component'
export { MemberInfoComponent } from './member-info/member-info.component'
export { EventsComponent } from './events/events.component'
export { ChatComponent } from './chat/chat.component'
export { SettingComponent } from './setting/setting.component'
export { AdminUsersComponent } from './admin/users/users.component'

