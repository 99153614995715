import { Injectable } from '@angular/core';
import { DatabaseService } from '../services/database.service';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class LoginService {
  user = null;

  constructor(public angularFireAuth: AngularFireAuth, public databaseService: DatabaseService) {
    //// Get auth data, then get firestore user document || null
    this.angularFireAuth.authState.subscribe(user => {
      if (user != null) {
        this.storeAuthInfo(user)
      } else {
        this.user = null;
      }
    });
  }

  get authenticated() {
    return this.user != null;
  }

  get id(): string {
    return this.angularFireAuth.auth.currentUser.uid;
  }

  login() {
    return this.angularFireAuth.auth.signInWithPopup(new auth.GoogleAuthProvider())
  }

  logout() {
    this.user = null
    return this.angularFireAuth.auth.signOut();
  }

  private storeAuthInfo(user) {
    let displayName = user.displayName;
    let email = user.email;
    let uid = user.uid
    let photoUrl = user.photoURL
    
    // gmail instead of email. User may want to use other email. Ser settings component
    this.databaseService.updateUser(uid,
      { 'gname': displayName, 'gmail': email, 'last_visit_date': new Date(), 'photo_url': photoUrl })

    this.databaseService.getUserById(uid).subscribe(user => {
      this.user = user;
      if (this.user.email == null || this.user.email == '')
        this.user.email = this.user.gmail;
      if (this.user.name == null || this.user.name == '')
        this.user.name = this.user.gname;
    })
  }
}
