import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import 'hammerjs';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { MyDatePickerModule } from 'mydatepicker';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import * as FIREBASE from './firebase';
import * as ROUTING from './routing';
import * as COMPONENTS from './components';
import * as SERVICES from './services';

import { ResponsiveModule } from 'ngx-responsive'

import {
  MatCheckboxModule,
  MatRadioModule,
  MatTabsModule,
  MatButtonModule,
  MatMenuModule,
  MatIconModule,
  MatCardModule,
  MatSidenavModule,
  MatFormFieldModule,
  MatInputModule,
  MatTooltipModule,
  MatToolbarModule,
  MatSnackBarModule
} from '@angular/material';

import { registerLocaleData } from '@angular/common';
import locales from '@angular/common/locales/sv';

registerLocaleData(locales);

@NgModule({
  declarations: [
    COMPONENTS.AppComponent,
    COMPONENTS.LoginComponent,
    COMPONENTS.LogoutComponent,
    COMPONENTS.PlayrightsComponent,
    COMPONENTS.HomeComponent,
    COMPONENTS.EventsComponent,
    COMPONENTS.ChatComponent,
    COMPONENTS.SettingComponent,
    COMPONENTS.AdminUsersComponent,
    COMPONENTS.MemberInfoComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(FIREBASE.firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    ROUTING.routing,
    ReactiveFormsModule,
  //  MaterialModule.forRoot(),
    MyDatePickerModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),   
    ResponsiveModule.forRoot(),
    MatCheckboxModule,
    MatRadioModule,
    MatTabsModule,
    MatSnackBarModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatToolbarModule,
    BrowserAnimationsModule
  ],
  providers: [SERVICES.LoginService, ROUTING.appRoutingProviders, SERVICES.ChatService, SERVICES.DatabaseService, SERVICES.HintonBookingService],
  bootstrap: [COMPONENTS.AppComponent]
})
export class AppModule { }
