import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../../../services/database.service';
import { User } from '../../../models/user';
// import { isWeekend } from 'date-fns';

@Component({
  selector: 'admin-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class AdminUsersComponent implements OnInit {

  private paidUsers: User[];
  private noPaidUsers: User[];
  private topPlayrightBookers = [];
  private topHigkPlayrightBookers = [];
  private topOsgkPlayrightBookers = [];
  private topNaturPlayrightBookers = [];
  private osgkBookingCount = 0
  private higkBookingCount = 0
  private naturBookingCount = 0

  constructor(private databaseService: DatabaseService) { }

  ngOnInit() {
    this.databaseService.getUsers().subscribe(users => {
      this.paidUsers = users.filter(u => { return u.paid == true }).sort((a, b) => {
        return this.compareByDate(a, b);
      });
      this.noPaidUsers = users.filter(u => { return u.paid != true }).sort((a, b) => {
        return this.compareByDate(a, b);
      });
    })
    let bookers = {}
    let higkBookers = {}
    let osgkBookers = {}
    let naturBookers = {}
  
    this.databaseService.getPlayrightBookings().subscribe(playrights => {
      let this_year = new Date().getFullYear()
      let prs = playrights.filter(p => {
        if (p.play_date.date.year != this_year) {
          return p
        }
        if (bookers[p.user_name] == undefined) {
          bookers[p.user_name] = { "all": 1, "weekend" : 0 };
        } else {
          // bookers[p.user_name]["all"] = bookers[p.user_name]["all"] + 1;
          bookers[p.user_name]["all"] += 1;
        }
        if (this.isWeekend(p.play_date)) {
          bookers[p.user_name]["weekend"] += 1;
        }
        if (p.golf_course_name == "HIGK") {
          // all days counter
          this.higkBookingCount += 1
          if (higkBookers[p.user_name] == undefined) {
            higkBookers[p.user_name] = { "all": 1, "weekend" : 0 };
          } else {
            higkBookers[p.user_name]["all"] += 1;
          }
        } else if (p.golf_course_name == "NATUR") {
            // all days counter
            this.naturBookingCount += 1
            if (naturBookers[p.user_name] == undefined) {
              naturBookers[p.user_name] = { "all": 1, "weekend" : 0 };
            } else {
              naturBookers[p.user_name]["all"] += 1;
            }
            // Weekend counter
            if (this.isWeekend(p.play_date)) {
              naturBookers[p.user_name]["weekend"] += 1;
            }
          } else {
          // all days counter
          this.osgkBookingCount += 1
          if (osgkBookers[p.user_name] == undefined) {
            osgkBookers[p.user_name] = { "all": 1, "weekend" : 0 };
          } else {
            osgkBookers[p.user_name]["all"] += 1;
          }
          // weekend counter
          if (this.isWeekend(p.play_date)) {
            osgkBookers[p.user_name]["weekend"] += 1;
          }
        }
        return p
      });

      // ============================================
      // Create items array all bookers
      let items = Object.keys(bookers).map(function (key) {
        return [key, bookers[key]]
      });

      // Sort the array based on the second element
      items.sort(function (first, second) {
        return <number>(second[1]["all"]) - <number>(first[1]["all"]);
      });
      this.topPlayrightBookers = items

      // ============================================
      // Create items array Hinton
      let higkItems = Object.keys(higkBookers).map(function (key) {
        return [key, higkBookers[key]]
      });

      // Sort the array based on the second element
      this.topHigkPlayrightBookers = higkItems.sort(function (first, second) {
        return <number>(second[1]["all]"]) - <number>(first[1]["all]"]);
      });

      // ============================================
      // Create items array Örestads
      let osgkItems = Object.keys(osgkBookers).map(function (key) {
        return [key, osgkBookers[key]]
      });

      console.log(osgkItems[0])

      // Sort the array based on the second element
      this.topOsgkPlayrightBookers = osgkItems.sort(function (first, second) {
        return <number>(second[1]["all]"]) - <number>(first[1]["all]"]);
      });
      console.log("osgkItem after sort")
      console.log(this.topOsgkPlayrightBookers[0])
      
      // ============================================
      // Create items array Natur
      let naturgkItems = Object.keys(naturBookers).map(function (key) {
        return [key, naturBookers[key]]
      });

      // Sort the array based on the second element
      this.topNaturPlayrightBookers = naturgkItems.sort(function (first, second) {
        return <number>(second[1]["all]"]) - <number>(first[1]["all]"]);
      });
    })
  }

  isWeekend(date) {
    let dd = new Date(date.date.year, date.date.month - 1, date.date.day);
    let dayOfWeek = dd.getDay();
    return dayOfWeek == 0 || dayOfWeek == 6;
  }

  ui_register_payment(user): void {
    console.log("Register payment for " + user.gname)
    this.databaseService.updateUser(user.key, { "paid": true })
  }

  ui_delete_user(user): void {
    console.log("Delete user " + user.gname)
    this.databaseService.deleteUser(user.key)
  }

  ui_sortByName(): void {
    this.paidUsers = this.paidUsers.sort((a, b) => {
      return this.compareByName(a, b);
    })
  }

  ui_sortByVisit(): void {
    this.paidUsers = this.paidUsers.sort((a, b) => {
      return this.compareByDate(a, b);
    })
    this.noPaidUsers = this.noPaidUsers.sort((a, b) => {
      return this.compareByDate(a, b);
    })
  }

  ui_sortByRestrictBooking(): void {
    this.paidUsers = this.paidUsers.sort((a, b) => {
      return this.compareByRestrictBookingThenName(a, b);
    })
  }

  ui_sortTopPRByName(): void {
    this.topPlayrightBookers = this.topPlayrightBookers.sort((a, b) => {
      return this.compareStringCaseInsensitive(a[0], b[0]);
    })
  }

  ui_sortNoPaidUserByName(): void {
    this.noPaidUsers = this.noPaidUsers.sort((a, b) => {
      return this.compareByName(a, b);
    })
  }

  ui_sortTopPRByCounter(): void {
    this.topPlayrightBookers = this.topPlayrightBookers.sort((first, second) => {
      return <number>second[1] - <number>first[1];
    })
  }

  ui_get_email(user: User): String {
    if (user.email && user.email != '')
      return user.email;
    return user.gmail;
  }

  ui_get_name(user: User): String {
    if (user.name && user.name != '')
      return user.name;
    return user.gname;
  }

  ui_toggle_restrict_booking(user: User): void {
    if (user.restrict_booking)
      this.databaseService.updateUser(user.key, { restrict_booking: false })
    else
      this.databaseService.updateUser(user.key, { restrict_booking: true })
    console.log("Toggle restrict hinton only for " + user.name)
  }
  
  ui_toggle_natur_booking(user: User): void {
    if (user.allow_book_course_natur)
      this.databaseService.updateUser(user.key, { allow_book_course_natur: false })
    else
      this.databaseService.updateUser(user.key, { allow_book_course_natur: true })
    console.log("Toggle allow_book_course_natur for " + user.name)
  }


  ui_exclude_osgk(user): void {
    if (user.excluded_course == "OsGK")
      this.databaseService.updateUser(user.key, { excluded_course: null })
    else
      this.databaseService.updateUser(user.key, { excluded_course: "OsGK" })
    console.log("Exclude Örestadgk for " + user.name)
  }

  ui_get_restrict_booking_by_username(user_name: String): String {
    if (!this.paidUsers)
      return ""
    let users = this.paidUsers.filter(u => { return u.name == user_name })
    if (users.length > 0 && users[0].restrict_booking)
      return "YES"
    return ""
  }

  compareByName(a: User, b: User): number {
    if (a.name == null) a.name = a.gname
    if (b.name == null) b.name = b.gname

    return this.compareStringCaseInsensitive(a.name, b.name);
  }

  compareByRestrictBookingThenName(a: User, b: User): number {
    if (a.restrict_booking && b.restrict_booking)
      return this.compareStringCaseInsensitive(a.name, b.name)
    if (a.restrict_booking)
      return -1
    else
      return 1
  }

  compareStringCaseInsensitive(a: String, b: String): number {
    if (a.toLowerCase() < b.toLowerCase())
      return -1;
    if (a.toLowerCase() > b.toLowerCase())
      return 1;
    return 0;
  }

  compareByDate(a: User, b: User): number {
    if (a.last_visit_date == null)
      return 1
    if (b.last_visit_date == null)
      return -1
    if (a.last_visit_date < b.last_visit_date)
      return 1;
    if (a.last_visit_date > b.last_visit_date)
      return -1;
    return 0;
  }
}
