import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../../services/database.service';
import { LoginService } from '../../services/login.service';
import { User } from '../../models/user';

@Component({
  selector: 'app-member-info',
  templateUrl: './member-info.component.html',
  styleUrls: ['./member-info.component.css']
})
export class MemberInfoComponent implements OnInit {
  private paidUsers: User[];
  private filteredUsers: User[];
  private searchName = "le";

  constructor(private loginService: LoginService,
    private databaseService: DatabaseService) { }

  ngOnInit() {
    this.databaseService.getUsers().subscribe(users => {
      this.paidUsers = users.filter(u => {
        if (!u.email || u.email == "") {
          u.email = u.gmail;
        }
        return u.paid == true
      }).sort((a, b) => {
        return this.compareByName(a, b);
      });
      this.performSearch();
    })
  }
  
  ui_onSearchChange(searchValue: string) {
    this.searchName = searchValue.toLowerCase();
    this.performSearch()
  }

  compareByName(a: User, b: User): Number {
    if (a.name == null) a.name = a.gname
    if (b.name == null) b.name = b.gname

    return this.compareStringCaseInsensitive(a.name, b.name);
  }

  compareStringCaseInsensitive(a: String, b: String): Number {
    if (a.toLowerCase() < b.toLowerCase())
      return -1;
    if (a.toLowerCase() > b.toLowerCase())
      return 1;
    return 0;
  }

  performSearch() {
    if (this.searchName != "" && this.searchName.length > 1) {
      this.filteredUsers = this.paidUsers.filter(u => {
        if (u.private) { return false }
        return u.name.toLowerCase().includes(this.searchName)
      })
    } else {
      this.filteredUsers = []
    }
  }
}