
export interface User {
    key?: string;
    gname: string;
    name: string;
    gmail: string;
    email: string;
    golfid: string;
    hcp: number;
    mobile: string;
    paid: boolean;
    admin: boolean;
    private: boolean;
    excluded_course: string;
    restrict_booking: boolean;
    last_visit_date: Date;
    photo_url: string;
    allow_book_course_natur: boolean;
}

export const EmptyUser = {
    gname: '',
    name: '',
    gmail: '',
    email: '',
    golfid: '',
    hcp: 0,
    mobile: '',
    paid: false,
    admin: false,
    private: false,
    excluded_course: '',
    restrict_booking: false,
    last_visit_date: new Date(),
    photo_url: 'https://pixy.org/src/142/1427272.png',
    allow_book_course_natur: false
}