import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../../services/database.service';
import { GolfCourse } from '../../models/golf_course';
import { PlayRightBooking } from '../../models/playright_booking';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  private today: String;
  private toDayBookings: null;
  private golfCourses: GolfCourse[];
  private isAdmin: Boolean

  constructor(public loginService: LoginService, private databaseService: DatabaseService) { 
    if (loginService.user) {
      this.isAdmin = loginService.user.admin;
    }
  }

  ngOnInit() {
    this.databaseService.getGolfCourses().subscribe(golfCourses => {
      this.golfCourses = golfCourses;
    });
    this.databaseService.getPlayrightBookings().subscribe(playrightBookings => {
      this.toDayBookings = playrightBookings.filter(booking => {
        return this.isToday(booking.play_date);
      }).sort((a, b) => {
        return this.compareCourseName(a, b);
      });
    });
    let ts_hms = new Date();
    this.today = ts_hms.getFullYear() + '-' + 
      ("0" + (ts_hms.getMonth() + 1)).slice(-2) + '-' + 
      ("0" + (ts_hms.getDate())).slice(-2) + ' ';
  }

  ui_getGolfCourseName(short_name: String): String {
    for (var i = 0; i < this.golfCourses.length; i++) {
      if (this.golfCourses[i].short_name == short_name) {
        return this.golfCourses[i].name;
      }
    }
    return "None"
  }

  ui_numDayBeforeToday(date) {
    let ts_hms = new Date();
  
    let dd = new Date(date.date.year, date.date.month - 1, date.date.day, ts_hms.getHours(), ts_hms.getMinutes());  
    let days = Math.round((ts_hms.getTime()-dd.getTime())/(1000*60*60*24));
    return days
  }

  isToday(date): Boolean {
    let today = new Date();
    return date.date.year == today.getFullYear() &&
      date.date.month == (today.getMonth() + 1) &&
      date.date.day == today.getDate()
  }

  compareCourseName(a: PlayRightBooking, b: PlayRightBooking): Number {
    if (a.golf_course_name < b.golf_course_name)
      return -1;
    if (a.golf_course_name > b.golf_course_name)
      return 1;
    if (a.user_name < b.user_name)
      return -1;
  }

  getPhotoUrl(booking): string {
    if (booking.photo_url == null || booking.photo_url == '') {
      return "https://pixy.org/src/142/1427272.png"
    }
    return booking.photo_url;
  }
}
