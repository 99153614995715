import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { LoggedInGuard } from './login.guard';
import { AdminLoggedInGuard } from './admin_login.guard';
import * as COMPONENTS from '../components';

const appRoutes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'home', component: COMPONENTS.HomeComponent },
    { path: 'playrights', component: COMPONENTS.PlayrightsComponent, canActivate: [LoggedInGuard] },
    { path: 'events', component: COMPONENTS.EventsComponent, canActivate: [LoggedInGuard] },
    { path: 'memberinfo', component: COMPONENTS.MemberInfoComponent, canActivate: [LoggedInGuard] },
    { path: 'chat', component: COMPONENTS.ChatComponent, canActivate: [LoggedInGuard] },
    { path: 'setting', component: COMPONENTS.SettingComponent, canActivate: [LoggedInGuard] },
    { path: 'login', component: COMPONENTS.LoginComponent },
    { path: 'logout', component: COMPONENTS.LogoutComponent },
    { path: 'admin_users', component: COMPONENTS.AdminUsersComponent, canActivate: [AdminLoggedInGuard] }
];

export const appRoutingProviders: any[] = [
    LoggedInGuard,
    AdminLoggedInGuard
];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);

