import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  private errorDuringLogout = false;

  constructor(private loginService: LoginService, private router: Router) { }

  ngOnInit() {
    console.log("Starting Logout Page. Authenticated is: " + this.loginService.authenticated);
    if (this.loginService.authenticated) {
      this.loginService.logout()
    }
    this.router.navigate(['/home']);
  }
}
