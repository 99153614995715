import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { ChatService } from "../../services/chat.service";
import { LoginService } from '../../services/login.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})

export class ChatComponent implements OnInit {
  private newMessage: string;
  private messages: Observable<any>;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  constructor(private chatService: ChatService, private loginService: LoginService) {
    this.messages = this.chatService.messages;
    this.messages.subscribe((messages) => {
      setTimeout(() => {
        this.scrollToBottom();
      });
    });
  }

  ngOnInit() {
    this.chatService.user = this.loginService.user;
  }

  ui_isYou(email: any): Boolean {
    return (email == this.chatService.user.email);
  }

  ui_isMe(email: any): Boolean {
    return (email != this.chatService.user.email);
  }

  ui_sendMessage(): void {
    this.chatService.sendMessage(this.newMessage);
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { console.log('Scroll to bottom failed yo!') }
  }
}
