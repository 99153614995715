import { Component, OnInit } from '@angular/core';
import { IMyOptions, IMyDateModel } from 'mydatepicker';
import { CalendarEvent } from 'angular-calendar';
import { startOfDay } from 'date-fns';
import { DatabaseService } from '../../services/database.service';
import { LoginService } from '../../services/login.service';
import { HintonBookingService } from '../../services/hinton.booking.service';
import { GolfCourse } from '../../models/golf_course';
import { PlayRightBooking } from '../../models/playright_booking';
import { MatSnackBar } from '@angular/material';
import { timeInterval } from 'rxjs/operators';

const colors: any = {
  red: { primary: '#ad2121', secondary: '#FAE3E3' },
  blue: { primary: '#1e90ff', secondary: '#D1E8FF' },
  yellow: { primary: '#e3bc08', secondary: '#FDF1BA' }
};

@Component({
  selector: 'app-playrights',
  templateUrl: './playrights.component.html',
  styleUrls: ['./playrights.component.css']
})
export class PlayrightsComponent implements OnInit {
  private today: Date;
  private bookDate;
  private playDate;
  private events: CalendarEvent[];
  private golfCourses: GolfCourse[];
  private golfCourseToBook: GolfCourse;
  private futureBookings: PlayRightBooking[];
  private myBookings: PlayRightBooking[];
  private myWeekendBookings: PlayRightBooking[];
  private playrightBooking: PlayRightBooking;
  private playrightBookingOnSelectedDate: PlayRightBooking[] = [];
  private hideCalender;
  private hideRule;
  private hideBokskogenOffer;
  private isHintonCourseSelected;
  private teetime;
  private pendingBookingOp;
  private myHiGKBookings;
  private myOsGKBookings;
  private myNaturBookings;
  

  myDatePickerOptions: IMyOptions = {
    // other options...
    dateFormat: 'yyyy-mm-dd',
    height: '34px',
    width: '260px',
    inline: false,
    editableDateField: false,
  };

  constructor(public loginService: LoginService, private databaseService: DatabaseService,
    private bookingService: HintonBookingService, private snackBar: MatSnackBar) {
    this.today = new Date();
    this.bookDate = { date: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() } };
    this.playDate = { date: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() } };
    this.hideCalender = loginService.user.admin != true;
    this.hideRule = true;
    this.hideBokskogenOffer = true;
    this.golfCourses = [];
    this.isHintonCourseSelected = false;
    this.pendingBookingOp = false;
  }

  ngOnInit() {
    this.databaseService.getGolfCourses().subscribe(golfCourses => {
      for (var c of golfCourses) {
        if (c.short_name == "HIGK") {
          let a = Object.assign({}, c)
          a.name = "Hinton - Kvarnby GK"
          this.golfCourses.push(a)
          let b = Object.assign({}, c)
          b.name = "Hinton - Rönnebäck GK"
          this.golfCourses.push(b)
          let h = Object.assign({}, c)
          h.name = "Hinton - Sofiedal GK"
          this.golfCourses.push(h)
        } else {
          this.golfCourses.push(c)
        }
      }
    });
    this.databaseService.getPlayrightBookingsByUid(this.loginService.id).subscribe(playrightBookings => {
      this.futureBookings = playrightBookings.filter(booking => {
        if (booking.golf_course_long_name == null) {
          booking.golf_course_long_name = booking.golf_course_name
        }
        return this.isFuture(booking.play_date);
      });
      let this_year = new Date().getFullYear()
      this.myBookings = playrightBookings.filter(booking => {
        return booking.play_date.date.year == this_year
      });
      this.myWeekendBookings = this.myBookings.filter(booking => {
        return this.isWeekend(booking.play_date)
      })
      this.myHiGKBookings = this.myBookings.filter(booking => {
        return booking.golf_course_name == "HIGK"
      })
      this.myNaturBookings = this.myBookings.filter(booking => {
        return booking.golf_course_name == "NATUR"
      })
      this.myOsGKBookings = this.myBookings.filter(booking => {
        return booking.golf_course_name == "OsGK"
      })
    });
    this.updateEvents();
  }

  // ===========================================================================
  // User interactions

  ui_onDateChanged(date: Date) {
    this.playDate = date;
    this.updatePlayrightBookingOnSelectedDate();
    if (!this.isFuture(date)) {
      this.openSnackBarWarning("Du har valt gårdagens datum");
    }
  }

  ui_performBooking() {
    if (this.allow_to_book()) {
      if (this.golfCourseToBook.short_name == "HIGK") {
        // start tid finns ?
        if (this.teetime == null) {
          this.openSnackBarWarning("Du måste ange starttid")
          return
        }
        // golf id ok ?
        let golfid = this.loginService.user.golfid
        // golf id without '-' ?
        if (/\d{9}/.test(golfid) == true) {
          golfid = golfid.substring(0,6) + '-' + golfid.substring(6,9) 
        }
        if (/\d{6}-\d{3}/.test(golfid) != true) {
          this.openSnackBarWarning("Din golf id är inte ok " + golfid + " . Ska vara YYMMDD-XXX")
          return
        }
        // book teetime
        let course_id = "427" // Sofiedal
        let course_name = this.golfCourseToBook.name
        if (course_name.includes("Rönnebäck")) {
          course_id = "425"
        } else if (course_name.includes("Kvarnby")) {
          course_id = "426"
        }
        let play_date = this.playDate.date.year
          + '-' + String(this.playDate.date.month).padStart(2, '0')
          + '-' + String(this.playDate.date.day).padStart(2, '0')
        let play_time = this.teetime

        this.pendingBookingOp = true;
        let jj = this.bookingService.bookTeeTime(course_id, golfid, play_date, play_time).subscribe((data: any) => {
          let booking_id = data.booking_id
          let teetime_timestamp = data.teetime
          play_time = this.teetime = this.extract_teetime(teetime_timestamp)

          console.log("==> book teetime with id", booking_id);
          this.databaseService.createPlayrightBooking({
            book_date: this.bookDate,
            play_date: this.playDate,
            golf_course_name: this.golfCourseToBook.short_name,
            uid: this.loginService.id,
            user_name: this.loginService.user.name,
            photo_url: this.loginService.user.private ? 'https://pixy.org/src/142/1427272.png' : this.loginService.user.photo_url,
            hinton_booking_id: booking_id,
            hinton_start_tid: play_time,
            golf_course_long_name: course_name
          })
          this.updatePlayrightBookingOnSelectedDate();
          this.openSnackBarInfo(`Spelrätten och starttid ${play_time} på ${course_name} är bokad`);
          this.pendingBookingOp = false;
        },
          err => {
            this.openSnackBarWarning(`Misslyckas boka spelrätten och starttid ${play_time} på ${course_name}`)
            console.log("Fel vid bokning av starttid", err);
          })
      } else {
        this.databaseService.createPlayrightBooking({
          book_date: this.bookDate,
          play_date: this.playDate,
          golf_course_name: this.golfCourseToBook.short_name,
          uid: this.loginService.id,
          user_name: this.loginService.user.name,
          photo_url: this.loginService.user.private ? 'https://pixy.org/src/142/1427272.png' : this.loginService.user.photo_url,
          golf_course_long_name: this.golfCourseToBook.name
        })
        this.updatePlayrightBookingOnSelectedDate();
        this.openSnackBarInfo("Spelrätten är bokad")
      }
    }
  }

  ui_performUnbooking() {
    if (this.playrightBooking) {
      if (this.playrightBooking.golf_course_name == "HIGK" && this.playrightBooking.hinton_booking_id != null) {
        let data = this.bookingService.cancelTeeTime(this.playrightBooking.hinton_booking_id).subscribe((data: any) => {
          console.log("==> cancel teetime", data);
          let play_time = this.playrightBooking.hinton_start_tid
          let course_name = this.playrightBooking.golf_course_long_name
          this.databaseService.deletePlayrightBooking(this.playrightBooking.key)
          this.playrightBooking = null;
          this.openSnackBarInfo(`Spelrätten och starttid ${play_time} på ${course_name} är avbokad`)
        },
          err => {
            this.openSnackBarInfo("OBS ! Starttiden kunde inte avboka ! Kontakta Chi Thu")
            console.log("Fel vid avboka starttid", err);
          })
      } else {
        this.databaseService.deletePlayrightBooking(this.playrightBooking.key)
        this.playrightBooking = null;
        this.openSnackBarInfo("Spelrätten är avbokad")
      }
    } else {
      this.openSnackBarWarning("Du måste välja en bokning först")
    }
    this.updatePlayrightBookingOnSelectedDate();
  }

  ui_select_golf_course(event, golfCourseToBook) {
    this.golfCourseToBook = golfCourseToBook;
    this.updatePlayrightBookingOnSelectedDate();
    this.isHintonCourseSelected = golfCourseToBook.short_name == "HIGK";
    if (this.golfCourseToBook.short_name == "NATUR") {
      if (! this.loginService.user.allow_book_course_natur) {
        this.openSnackBarInfo("Du får boka denna golfklubb men glömmer inte Swisha extra medlemsavgift till Chi Thu Le")
        return false
      }
    }
  }

  ui_select_playright_booking(event, playrightBooking) {
    this.playrightBooking = playrightBooking;
  }

  ui_showCalender() {
    this.hideCalender = false;
  }

  ui_showRule() {
    this.hideRule = false;
  }
  
  ui_showBokskogenOffer() {
    this.hideBokskogenOffer = false;
  }

  ui_getCalenderPlayDate() {
    let date = this.playDate;
    return new Date(date.date.year, date.date.month - 1, date.date.day);
  }

  // ===========================================================================
  // Helper methods

  updateEvents() {
    this.databaseService.getPlayrightBookings().subscribe(playrightBookings => {
      let calPlayRightBookings = playrightBookings.filter(booking => {
        return true; // this.isAroundNumDays(booking.play_date, 15);
      })
      this.events = calPlayRightBookings.map(booking => {
        let date = new Date(booking.play_date.date.year, booking.play_date.date.month - 1, booking.play_date.date.day);
        let title_prefix = "H - ";
        if (booking.golf_course_name == 'OsGK') {
          title_prefix = "Ö - ";
        } else if (booking.golf_course_name == 'NATUR') {
          title_prefix = "N - ";
        }
        let a_color = colors.yellow
        if (booking.golf_course_name == 'OsGK') {
          a_color = colors.blue;
        } else if (booking.golf_course_name == 'NATUR') {
          a_color = colors.red
        }
        let start_time = booking.hinton_start_tid ? " - " + booking.golf_course_long_name + " - " + booking.hinton_start_tid : "";
        return {
          start: startOfDay(date),
          title: title_prefix + booking.user_name + start_time,
          color: a_color
        };
      })
    })
  }

  isFuture(date) {
    if (date.date.year > this.today.getFullYear())
      return true;

    if (date.date.year == this.today.getFullYear() && date.date.month > (this.today.getMonth() + 1))
      return true;

    return (date.date.year == this.today.getFullYear()) &&
      (date.date.month == (this.today.getMonth() + 1)) &&
      date.date.day >= (this.today.getDate())
  }

  isAroundNumDays(date, num_days) {
    if (date.date.year != this.today.getFullYear()) return false;
    let d = date.date.month * 30 + date.date.day
    let t = (this.today.getMonth() + 1) * 30 + this.today.getDate()
    if (d < t) return (t - d) < num_days;
    if (d >= t) return (d - t) < num_days;
  }

  isToday(date) {
    return date.date.year == this.today.getFullYear() &&
      date.date.month == (this.today.getMonth() + 1) &&
      date.date.day == (this.today.getDate())
  }

  numDayFromToday(date) {
    let dd = new Date(date.date.year, date.date.month - 1, date.date.day, this.today.getHours(), this.today.getMinutes());
    let days = Math.round((dd.getTime() - this.today.getTime()) / (1000 * 60 * 60 * 24));
    console.log("==> number of days from today", days)
    return days
  }

  isWeekend(date) {
    let dd = new Date(date.date.year, date.date.month - 1, date.date.day);
    let dayOfWeek = dd.getDay();
    return dayOfWeek == 0 || dayOfWeek == 6;
  }

  allow_to_book() {
    // No golf course is selected
    if (this.golfCourseToBook == null) {
      this.openSnackBarWarning("Du har inte valt en golfbana")
      return false;
    }
    // Yesterday 
    if (this.isFuture(this.playDate) == false) {
      this.openSnackBarWarning("Gårdagens spelrätter kan ej bokas")
      return false
    }
    // Fully booked
    if (this.golfCourseToBook.playrights <= (this.playrightBookingOnSelectedDate.length)) {
      this.openSnackBarWarning("Banan är fullbokad")
      return false;
    }
    // Weekend check
    if (this.isWeekend(this.playDate) && this.golfCourseToBook.weekend == false) {
      this.openSnackBarWarning("Man får bara spela helgfria vardagar på denna golfklubb")
      return false;
    }
    // Already book
    let alreadyBooking = this.futureBookings.filter(booking => {
      return booking.golf_course_name == this.golfCourseToBook.short_name &&
        this.isSameGolfCourseAndDate(booking);
    })
    if (alreadyBooking.length > 0) {
      this.openSnackBarWarning("Du har redan bokat samma golfbanan och spel dag")
      return false
    }

    let todayBooking = this.futureBookings.filter(booking => {
      return this.isToday(booking.play_date);
    })

    if (this.isToday(this.playDate)) {
      // book today
      if (todayBooking.length > 0) {
        this.openSnackBarWarning("Du har redan bokat en spelrätt idag")
        return false
      } else {
        if (this.futureBookings.length > 1) {
          this.openSnackBarWarning("Du får inte boka fler spelrätt")
          return false
        }
      }
    } else {
      // Book future
      if (todayBooking.length > 0) {
        if (this.futureBookings.length > 1) {
          this.openSnackBarWarning("Du får inte boka fler spelrätt")
          return false
        }
      } else {
        console.log(this.futureBookings)
        if (this.futureBookings.length > 0) {
          this.openSnackBarWarning("Du får inte boka fler spelrätt")
          return false
        }
      }
    }
    
    if (this.numDayFromToday(this.playDate) > 14) {
      this.openSnackBarWarning("Du får bara boka 14 dagar i förväg.")
      return false
    }
    if (this.loginService.user.restrict_booking == true) {
      if (this.isWeekend(this.playDate) && this.numDayFromToday(this.playDate) > 3) {
        this.openSnackBarWarning("Du får bara boka helg på 3 dagar i förväg.")
        return false
      }
    }
    if (this.myNaturBookings.length > 10) {
      if (! this.loginService.user.admin) {
        this.openSnackBarWarning("Gick inte att boka. Vg kontakta Chi Thu Le.")
        return false
      }
    }
    
    if (this.golfCourseToBook.short_name == "HIGK" && this.playDate.date.day > 9 && this.playDate.date.month > 9) {
      this.openSnackBarWarning("Gick inte att boka Hinton GK. Vg kontakta Chi Thu Le.")
      return false
    }
    // this.openSnackBarWarning("Vi har inte betalat fakturor än!")
    // return false;
    return true;
  }

  isSameGolfCourseAndDate(playright) {
    return this.golfCourseToBook &&
      playright.golf_course_name == this.golfCourseToBook.short_name &&
      playright.play_date.date.year == this.playDate.date.year &&
      playright.play_date.date.month == this.playDate.date.month &&
      playright.play_date.date.day == this.playDate.date.day;
  }

  bookingIsToday(playright) {
    return this.isToday(playright.play_date);
  }

  updatePlayrightBookingOnSelectedDate() {
    this.databaseService.getPlayrightBookings().subscribe(playrights => {
      let prs = playrights.filter(p => { return this.isSameGolfCourseAndDate(p) });
      this.playrightBookingOnSelectedDate = prs;
    })
  }

  openSnackBarWarning(message: string) {
    this.snackBar.open(message, "", {
      duration: 6000,
      panelClass: ['red-snackbar']
    });
  }

  openSnackBarInfo(message: string) {
    this.snackBar.open(message, "", {
      duration: 6000,
      panelClass: ['blue-snackbar']
    });
  }

  extract_teetime(unix_timestamp: number) {
    var date = new Date(unix_timestamp * 1000);
    var hours = date.getHours();
    var minutes = "0" + date.getMinutes();
    var seconds = "0" + date.getSeconds();
    var formattedTime = hours + ':' + minutes.substr(-2);
    console.log("format time", formattedTime)
    return formattedTime;
  }
}
