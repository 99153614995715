import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class HintonBookingService {

    private API_SERVER = "https://hinton-teetime-booking.azurewebsites.net";
    
    constructor(private httpClient: HttpClient) { }


    handleError(error: HttpErrorResponse) {
        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side errors
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        // window.alert(errorMessage);
        return throwError(errorMessage);
    }

    bookTeeTime(courseId: String, golfId: String, playDate : String, playTime: String) {
        console.log("Booking Teetime", courseId, golfId, playDate, playTime)
        let orgData = { course_id: courseId, golf_id: golfId, play_date: playDate, play_time: playTime}
        let jsonData = { payload : btoa("#QiOiI0Mjc"+(btoa(JSON.stringify(orgData))))}
        return this.httpClient.post(this.API_SERVER + "/teetime", jsonData).pipe(catchError(this.handleError));
    }

    cancelTeeTime(bookingId: String) {
        console.log("Cancel teetime ", bookingId)
        return this.httpClient.delete(this.API_SERVER + "/teetime/"+bookingId).pipe(catchError(this.handleError));
    }
}