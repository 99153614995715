import { Injectable } from '@angular/core';
import { AngularFireDatabase,  } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'

import { User } from '../models/user';
import { GolfCourse } from '../models/golf_course';
import { PlayRightBooking } from '../models/playright_booking';

@Injectable()
export class DatabaseService {
  
  constructor(public af: AngularFireDatabase) {
  }

  getGolfCourses(): Observable<any> {
    return this.af.list('/golfcourses').valueChanges();
  }

  getUsers(): Observable<any> {
    return this.af.list('/users').snapshotChanges().pipe(
      map(actions => 
        actions.map(a => ({ key: a.key, ...a.payload.val() }))
      )
    )
  }

  getUserById(uid: string): Observable<any> {
    return this.af.object('/users/' + uid).valueChanges();
  }

  updateUser(uid, values) {
    let user = this.af.object('users/' + uid);
    user.update(values).then(() => {
      console.log("updateCurrentUser: saved data")
      user.valueChanges().subscribe(action => {
        console.log(action)
      })
    });
  }

  deleteUser(uid) {
    let user = this.af.object('users/' + uid);
    user.remove();
  }
  
  getPlayrightBookings(): Observable<any> {
    return this.af.list('/playright_bookings').valueChanges();
  }

  getPlayrightBookingsByUid(uid: string): Observable<any> {
    return this.af.list('/playright_bookings',
      ref => ref.orderByChild('uid').equalTo(uid)).snapshotChanges().pipe(
        map(actions => 
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        )
      )
  }

  deletePlayrightBooking(key) {
    this.af.object('/playright_bookings/' + key).remove().
      then(_ => console.log('booking removed')).catch(err => console.log(err))
  }

  createPlayrightBooking(values) {
    this.af.list('/playright_bookings').push(values)
  }
}
