
import { LoginService } from './../services/login.service';
import { Injectable } from '@angular/core';
import {
    Router, CanActivate, ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';

@Injectable()
export class LoggedInGuard implements CanActivate {
    constructor(private loginService: LoginService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let url: string = state.url;

        let authenticated = false;

        if (this.loginService.authenticated) {
            authenticated = true;
        }
        else {
            // Store the attempted URL for redirecting
            this.router.navigate(['/login']);
        }
        return authenticated;
    }
}