import { Injectable } from "@angular/core";
import { AngularFireDatabase } from '@angular/fire/database';
import { User } from '../models/user';

@Injectable()
export class ChatService {
  public messages: any;
  public user:User;

  constructor(public af: AngularFireDatabase) {
    this.messages = this.af.list('messages').valueChanges();
  }

  /**
   * Saves a message to the Firebase Realtime Database
   * @param text
   */
  sendMessage(text) {
    var message = {
      message: text,
      displayName: this.user.name,
      email: this.user.email,
      timestamp: Date.now()
    };
    this.af.list('messages').push(message);
  }
}