import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { DatabaseService } from '../../services/database.service'
import { FormBuilder, Validators } from '@angular/forms';
import { User, EmptyUser } from '../../models/user';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css'],
})
export class SettingComponent implements OnInit {
  private error: any;
  private loginForm;
  private user: User = EmptyUser;
  
  constructor(
    private loginService: LoginService,
    private databaseService: DatabaseService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getUser();
    this.createForm();
  }

  //registers the user and logs them in
  ui_updateProfile(event) {
    if (this.loginForm.valid) {
      this.openSnackBar("Dina uppgifter är sparad.")
      this.databaseService.updateUser(this.loginService.id, this.loginForm.value);
    } else {
      this.openSnackBar("Du måste fyller i alla fält.")
    }
  }

  getUser(): void {
    this.user = this.loginService.user;
    if (this.user.private == null) {
      // this.user.private not allow to be null. It will failed when save. I don't know why.
      this.user.private = false;
    }
  }

  createForm(): void {
    this.loginForm = this.fb.group(
      {
        name: [null, Validators.compose([Validators.required, Validators.minLength(5)])],
        email: [null, Validators.required],
        golfid: [null, Validators.compose([Validators.required, Validators.minLength(10)])],
        hcp: [null, Validators.compose([Validators.required, Validators.minLength(1)])],
        mobile: [null, Validators.required],
        private: [null, null],
      });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "", {
      duration: 5000,
    });
  }
}
